<template>
  <div class="image">
    <img
      v-lazy="`${$config.IMG_HOST}/375x440/${image.value}`"
      alt=""
      class="img-fluid"
    />
  </div>
</template>

<script>
export default {
  props: {
    image: [Array, Object],
  },
};
</script>

<style lang="scss" scoped>
.image {
  margin-bottom: rem(16px);
  @include flex(center, center);
  height: 100%;
  img {
    border-radius: 30px;
    overflow: hidden;
  }
}
</style>
