<template>
  <div>
    <div class="table" v-if="funds.length != 0">
      <div class="table__head">
        <div class="table__row">
          <div class="table__row--cell" data-width="12">Date</div>
          <div class="table__row--cell">Status</div>
          <div class="table__row--cell" data-width="12">Amount</div>
        </div>
      </div>
      <div class="table__body">
        <base-skeleton-loader
          type="billing"
          :count="this.limit"
          v-if="$apollo.loading && isFirst"
        ></base-skeleton-loader>
        <funding-card :data="fund" v-for="fund in funds" :key="fund.index" />
        <infinite-loading
          @infinite="infiniteHandlerFunds"
          :identifier="infiniteId"
          :distance="30"
          v-if="!isFirst"
        ></infinite-loading>
      </div>
    </div>
    <div class="no-record" v-if="funds.length == 0 && !$apollo.loading.funds">
      No Record Found
    </div>
  </div>
</template>

<script>
import { FUNDS } from "@/graphql/brand/query";
let date = new Date();
let startDate = new Date(date.getFullYear(), date.getMonth(), 1)
  .getTime()
  .toString();
let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  .getTime()
  .toString();
export default {
  props: {
    filterDate: Object,
    refetch: Boolean,
  },
  components: {
    FundingCard: () =>
      import(
        /* webpackChunkName: "influencerDetailGallery" */ "@/components/brand/billing/partials/FundingCard.vue"
      ),
  },
  data() {
    return {
      page: 1,
      limit: 10,
      startDate: startDate,
      endDate: endDate,
      infiniteId: +new Date(),
      hasMore: true,
      isFirst: true,
      funds: [],
    };
  },
  apollo: {
    funds: {
      query: FUNDS,
      variables() {
        return {
          page: 1,
          limit: this.limit,
          fromDate: this.startDate,
          toDate: this.endDate,
        };
      },
      result(data) {
        if (data.data.funds.length < this.limit) {
          this.hasMore = false;
        } else {
          this.hasMore = true;
          this.infiniteId += 1;
        }
        this.isFirst = false;
      },
    },
  },
  watch: {
    filterDate: {
      handler() {
        this.infiniteId += 1;
        this.page = 1;
        (this.isFirst = true), (this.startDate = this.filterDate.startDate);
        this.endDate = this.filterDate.endDate;
        this.$apollo.queries.funds.refetch();
      },
      deep: true,
    },
    refetch() {
      if (this.refetch == true) {
        this.$apollo.queries.funds.refetch();
      }
    },
  },
  methods: {
    infiniteHandlerFunds($state) {
      if (this.hasMore) {
        setTimeout(() => {
          try {
            this.page++;
            this.$apollo.queries.funds.fetchMore({
              variables: {
                page: this.page,
                limit: this.limit,
                fromDate: this.startDate,
                toDate: this.endDate,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                const newFunds = fetchMoreResult.funds;
                if (newFunds.length) {
                  $state.loaded();
                } else {
                  $state.complete();
                  this.resetLoading();
                }
                if (newFunds.length < this.limit) {
                  $state.complete();
                }
                return {
                  funds: [...previousResult.funds, ...newFunds],
                };
              },
            });
          } catch (err) {
            $state.error();
          }
        }, 500);
      } else {
        $state.complete();
      }
    },
    resetLoading() {
      this.hasMore = false;
      this.page = 1;
    },
  },
};
</script>

<style lang="scss" scoped></style>
