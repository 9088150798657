<template>
  <div>
    <div class="table" v-if="transactions.length != 0">
      <div class="table__head">
        <div class="table__row">
          <div class="table__row--cell" data-width="12">Date</div>
          <div class="table__row--cell">For</div>
          <div class="table__row--cell" data-width="12">Amount</div>
        </div>
      </div>
      <div class="table__body">
        <base-skeleton-loader
          type="billing"
          :count="this.limit"
          v-if="$apollo.loading && isFirst"
        ></base-skeleton-loader>
        <billing-card
          :data="transaction"
          v-for="transaction in transactions"
          :key="transaction.index"
        />
        <infinite-loading
          @infinite="infiniteHandler"
          :identifier="infiniteId"
          :distance="30"
          v-if="!isFirst"
        ></infinite-loading>
      </div>
    </div>
    <div class="no-record" v-if="transactions.length == 0 && !$apollo.loading">
      No Record Found
    </div>
  </div>
</template>

<script>
import { TRANSACTIONS } from "@/graphql/brand/query";
let date = new Date();
let startDate = new Date(date.getFullYear(), date.getMonth(), 1)
  .getTime()
  .toString();
let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  .getTime()
  .toString();
export default {
  props: {
    filterDate: Object,
  },
  data() {
    return {
      page: 1,
      limit: 3,
      startDate: startDate,
      endDate: endDate,
      infiniteId: +new Date(),
      hasMore: true,
      isFirst: true,
      transactions: [],
    };
  },
  components: {
    BillingCard: () =>
      import(
        /* webpackChunkName: "influencerDetailGallery" */ "@/components/common/billing/BillingCard.vue"
      ),
  },
  created() {
    if (this.filterDate.startDate != null) {
      this.startDate = this.filterDate.startDate;
      this.endDate = this.filterDate.endDate;
    }
  },
  apollo: {
    transactions: {
      query: TRANSACTIONS,
      variables() {
        return {
          page: 1,
          limit: this.limit,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      result(data) {
        if (data.data.transactions.length < this.limit) {
          this.hasMore = false;
        } else {
          this.hasMore = true;
        }
        this.isFirst = false;
      },
    },
  },
  watch: {
    filterDate: {
      handler() {
        this.infiniteId += 1;
        this.page = 1;
        (this.isFirst = true), (this.startDate = this.filterDate.startDate);
        this.endDate = this.filterDate.endDate;
      },
      deep: true,
    },
  },
  methods: {
    infiniteHandler($state) {
      if (this.hasMore) {
        setTimeout(() => {
          try {
            this.page++;
            this.$apollo.queries.transactions.fetchMore({
              variables: {
                page: this.page,
                limit: this.limit,
                startDate: this.startDate,
                endDate: this.endDate,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                const newTransactions = fetchMoreResult.transactions;
                if (newTransactions.length) {
                  $state.loaded();
                } else {
                  $state.complete();
                  this.hasMore = false;
                }
                if (newTransactions.length < this.limit) {
                  this.hasMore = false;
                }
                return {
                  transactions: [
                    ...previousResult.transactions,
                    ...newTransactions,
                  ],
                };
              },
            });
          } catch (err) {
            $state.error();
          }
        }, 500);
      } else {
        $state.complete();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
